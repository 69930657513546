.sidebar-col {
    width: fit-content !important;
}

.sidebar-row {
    width: auto;
    margin-top: -5vh !important;
}

/*@media (max-width: 500px) {*/
/*    .sidebar-row {*/
/*        margin-top: 10vh !important;*/
/*    }*/

/*}*/

@media (max-width: 1600px) {
    .sidebar-row {
        margin-top: -10vh !important;
    }
}

@media (max-width: 1360px) {
    .sidebar-row {
        margin-top: -11vh !important;
    }
}
