@media (max-width: 992px) {
    .navbar-collapse {
        position: absolute;
        top: 50px;
        left: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        width: 100%;
        height: 1000px;
        transition: all 0.4s ease;
        display: block;
        background-color: #ee731f;
        z-index: 100;
    }

    .navbar-collapse.collapsing {
        height: 1000px !important;
        margin-left: 50%;
        left: 50%;
        transition: all 0.2s ease;
    }

    .navbar-collapse.show {
        left: 0;
        background-color: #ee731f;
    }


    nav .navbar-nav li a {
        color: white !important;
    }

    @media screen and (max-width: 992px) {

        nav .navbar-nav li a {
            color: #4d4f20 !important;
        }

    }


}

