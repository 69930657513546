#background-video {
    overflow: hidden;

    right: 0;
    min-width: 100%;
    max-height: 100%;
    z-index: -1;
    /*-webkit-transform: scaleX(2);*/
    /*-moz-transform: scaleX(2);*/

    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*-o-object-fit: cover;*/

    /*transform: translateX(00%) translateY(10%);*/
}

#background-video-image {
    /*background-image: url("../assets/background/background_video.png");*/
    background-color: black;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -2;
    height: 100%;
    width: 100%;
    -webkit-filter: blur(3px);
    filter: blur(3px);
    transform: scale(1.03);
    /*backdrop-filter: blur(6px);*/

}

@media only screen and (min-aspect-ratio: 21/9) {

}


@media only screen and (max-width: 700px) {
    #background-video {
        /*transform: translateX(20%) translateY(00%);*/
        /*-webkit-transform: translateX(20%) translateY(00%);*/
    }
}


@media only screen and (max-width: 600px) {
    .player-wrapper {
        position: relative;
        padding-top: 56.25%
    }
}

@media only screen and (min-width: 600px) {
    .player-wrapper {
        position: relative;
        padding-top: 36.25%
    }
}

@media only screen and (min-width: 800px) {
    .player-wrapper {
        position: relative;
        padding-top: 32.25%
    }
}

@media only screen and (min-width: 1000px) {
    .player-wrapper {
        position: relative;
        padding-top: 22.25%
    }
}

@media screen and (min-width: 1000px) {
    .player-wrapper {
        position: relative;
        padding-top: 16.25%
    }
}


.react-player {
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}


#background-video-between-homes {
    overflow: hidden;

    right: 0;
    min-width: 120%;
    max-height: 120%;
    z-index: -1;
    overflow: hidden;
    /*-webkit-transform: scaleX(2);*/
    /*-moz-transform: scaleX(2);*/

    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*-o-object-fit: cover;*/

    /*transform: translateX(00%) translateY(10%);*/
}
