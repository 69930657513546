/* Base styles for the placard */
.movie-placard {
    background-color: #ff0000; /* Red background */
    color: #fff;
    padding: 20px;
    width: 300px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    position: relative;
}

/* Border styles */
.movie-placard:before,
.movie-placard:after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 5px solid #ff0; /* Yellow border */
    z-index: -1;
    border-radius: 50%;
    animation: blink 1s infinite alternate;
}

/* Blinking effect */
@keyframes blink {
    from {
        box-shadow: 0 0 10px #ff0, 0 0 20px #ff0, 0 0 30px #ff0;
    }
    to {
        box-shadow: none;
    }
}