@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url('https://fonts.googleapis.com/css2?family=Sono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alike+Angular&family=Asul&family=Concert+One&family=Eczar&family=Fruktur&family=Kavoon&family=Neuton&family=Roboto:wght@300&family=Space+Mono&family=Tillana&family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');


@font-face {
    font-family: "American Typewriter";
    src: url('assets/fonts/typewriter/typewriter.ttf') format('truetype');
}

@font-face {
    font-family: "Active Regular";
    src: url("assets/fonts/ActiveRegular.OTF") format("opentype");
}

@font-face {
    font-family: "Gotham";
    src: url("assets/fonts/GothamMedium.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham Light";
    src: url("assets/fonts/GothamLight.otf") format("opentype");
}

body {
    margin: 0;
    font-family: 'Gotham', "Active Regular", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
}

.no-padding {
    padding: 0;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
}

h1, h2, h3, h4, h5, p {
    /*font-size: 100%;*/
    font-family: 'Gotham', sans-serif;
}

.thumbs {
    display: flex;
    justify-content: center;
}

.special-font {
    font-family: 'Gotham', sans-serif;
}

/*a {*/
/*    font-family: 'Gotham', sans-serif;*/
/*}*/

/*a {*/
/*    color: #4d4f20;*/
/*}*/

.upper-case {
    text-transform: uppercase;
}

.white-filter {
    filter: invert(95%) sepia(0%) saturate(2552%) hue-rotate(133deg) brightness(106%) contrast(102%);
}

.button-custom {
    border-radius: 0 !important;
}


.super-hover:hover {
    transition: 0.5s;
    transform: scale(1.04);
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.super-hover {
    transition: 0.5s;
    transform: scale(1);
}

.logo {
    max-width: 200px !important;
}

@media only screen and (max-width: 450px) {
    .logo {
        max-width: 160px !important;
    }
}


@media only screen and (min-width: 0px) and (min-height: 612px) {
    .index-page-container {
        height: 650vh
    }
}

@media only screen and (min-width: 330px) and (min-height: 612px) {
    .index-page-container {
        height: 580vh
    }
}

@media only screen and (min-width: 360px) and (min-height: 612px) {
    .index-page-container {
        height: 540vh
    }
}

@media only screen and (min-width: 410px) and (min-height: 612px) {
    .index-page-container {
        height: 505vh
    }
}

@media only screen and (min-width: 424px) and (min-height: 612px) {
    .index-page-container {
        height: 500vh
    }
}

@media only screen and (min-width: 512px) and (min-height: 612px) {
    .index-page-container {
        height: 470vh
    }
}

@media only screen and (min-width: 575px) and (min-height: 612px) {
    .index-page-container {
        height: 495vh
    }
}

@media only screen and (min-width: 640px) and (min-height: 612px) {
    .index-page-container {
        height: 491vh
    }
}

@media only screen and (min-width: 768px) and (min-height: 612px) {
    .index-page-container {
        height: 260vh
    }
}

@media only screen and (min-width: 820px) and (min-height: 612px) {
    .index-page-container {
        height: 250vh
    }
}

@media only screen and (min-width: 920px) and (min-height: 612px) {
    .index-page-container {
        height: 240vh
    }
}

@media only screen and (min-width: 976px) and (min-height: 612px) {
    .index-page-container {
        height: 230vh
    }
}

@media only screen and (min-width: 1280px) and (min-height: 612px) {
    .index-page-container {
        height: 200vh
    }
}

@media only screen and (min-height: 680px) {
    .index-page-container {
        height: 175vh
    }
}

@media only screen and (max-width: 1300px) and (min-height: 680px) {
    .index-page-container {
        height: 185vh
    }
}

@media only screen and (max-width: 1200px) and (min-height: 680px) {
    .index-page-container {
        height: 195vh
    }
}

@media only screen and (max-width: 1000px) and (min-height: 680px) {
    .index-page-container {
        height: 220vh
    }
}

@media only screen and (max-width: 880px) and (min-height: 680px) {
    .index-page-container {
        height: 230vh
    }
}

@media only screen and (max-width: 770px) and (min-height: 680px) {
    .index-page-container {
        height: 450vh
    }
}

@media only screen and (max-width: 570px) and (min-height: 680px) {
    .index-page-container {
        height: 430vh
    }
}

@media only screen and (max-width: 460px) and (min-height: 680px) {
    .index-page-container {
        height: 460vh
    }
}

@media only screen and (max-width: 400px) and (min-height: 680px) {
    .index-page-container {
        height: 480vh
    }
}

@media only screen and (max-width: 360px) and (min-height: 680px) {
    .index-page-container {
        height: 520vh
    }
}

@media only screen and (max-width: 330px) and (min-height: 680px) {
    .index-page-container {
        height: 570vh
    }
}

@media only screen and (min-height: 765px) {
    .index-page-container {
        height: 150vh
    }
}

@media only screen and (max-width: 1400px) and (min-height: 765px) {
    .index-page-container {
        height: 160vh
    }
}

@media only screen and (max-width: 1300px) and (min-height: 765px) {
    .index-page-container {
        height: 170vh
    }
}

@media only screen and (max-width: 1100px) and (min-height: 765px) {
    .index-page-container {
        height: 180vh
    }
}

@media only screen and (max-width: 980px) and (min-height: 765px) {
    .index-page-container {
        height: 200vh
    }
}

@media only screen and (max-width: 800px) and (min-height: 765px) {
    .index-page-container {
        height: 225vh
    }
}

@media only screen and (max-width: 768px) and (min-height: 765px) {
    .index-page-container {
        height: 400vh
    }
}

@media only screen and (max-width: 576px) and (min-height: 765px) {
    .index-page-container {
        height: 380vh
    }
}

@media only screen and (max-width: 480px) and (min-height: 765px) {
    .index-page-container {
        height: 400vh
    }
}

@media only screen and (max-width: 430px) and (min-height: 765px) {
    .index-page-container {
        height: 420vh
    }
}

@media only screen and (max-width: 370px) and (min-height: 765px) {
    .index-page-container {
        height: 450vh
    }
}

@media only screen and (max-width: 335px) and (min-height: 765px) {
    .index-page-container {
        height: 550vh
    }
}

.memory-nav-link {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.memory-page-container {
    min-width: 100vw;

}


.memory-scrollable-wrapper {
    display: inline-flex !important;
    flex-direction: row !important;
    overflow-x: visible !important;
    overflow-y: hidden !important;
    height: 93vh !important;

    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: -3em !important;
    scroll-behavior: smooth !important;
    background-color: #FFFFFF;

}

.memory-scrollable-wrapper.div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.memory-scrollable-image {
    flex-shrink: 0;
}

.memory-scrollable-arrow {

    padding: 35vh 0;
    flex-shrink: 0;
}


.memory-scrollable-arrow button {
    border: none;
    padding: 0;
    background: none;
}

.memory-scrollable-text {
    /*padding: 18vh 2em;*/
    min-width: 30em !important;
    margin: auto;
    padding-bottom: 22vh;
    /*margin-left: 5vw;*/
    /*margin-right: 5vw;*/


    font-family: 'Gotham';
    text-align: justify;
    /*text-align-last: justify;*/
}

.date-text {
    font-family: 'Gotham';
    text-align: justify;
    margin-top: 5px;
    margin-left: 15px;
}

.date-timeline-text {
    font-family: 'Gotham';
    text-align: justify;
    margin-top: 5px;
    margin-left: 5px;
}

.memory-img {
    height: 70vh !important;
}

.memory-arrow {
    height: 3vh !important;
}

.memory-arrow:hover {
    cursor: pointer;
}