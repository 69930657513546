.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active {
    color: #ffffff !important;
    background-color: #ff6700 !important;
    border-color: #ff6900 !important;
}

.btn-orange {
    background-color: #ee731f !important;
    border-color: #ee731f !important;
}