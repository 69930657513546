
.page_ana {
    z-index: 1;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear;
    -ms-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear;
}

.page_ana:hover {
    background-image: url(../assets/artist_presentation_pictures/ana.webp);
    background-size: cover;
    background-position: center center;
    -webkit-transition: background-color 0.5s linear;
    -moz-transition: background-color 0.5s linear;
    -o-transition: background-color 0.5s linear;
    -ms-transition: background-color 0.5s linear;
    transition: background-color 0.5s linear;
}

.page_ana:hover .text_hover {
    color: white;
    -webkit-transition: color 0.5s linear;
    -moz-transition: color 0.5s linear;
    -o-transition: color 0.5s linear;
    -ms-transition: color 0.5s linear;
    transition: color 0.5s linear;
}

.page_caisa:hover {
    background-image: url(../assets/artist_presentation_pictures/caisa_2.webp);
    background-position: 75% center;
}

.page_julia:hover {
    background-image: url(../assets/artist_pictures/julia/picture_0.webp);
    background-position: center bottom;

}

.page_leticia:hover {
    background-image: url(../assets/artist_presentation_pictures/leticia_2.webp);
    background-position: center left;
}

.page_marina:hover {
    background-image: url(../assets/artist_presentation_pictures/marina.webp);
}

.page_tatiane:hover {
    background-image: url(../assets/artist_pictures/tatiane/picture_2.webp);
    background-position: right bottom;
}

.page_renata:hover {
    background-image: url(../assets/artist_presentation_pictures/renata.webp);
    background-position: right bottom;

}

@media only screen and (max-width: 768px) {

    .text_hover {
        color: white;
    }

    .page_ana {
        background-image: url(../assets/artist_presentation_pictures/ana.webp);
        background-size: cover;
        background-position: center center;
    }

    .page_caisa {
        background-image: url(../assets/artist_presentation_pictures/caisa_2.webp);
        background-position: 75% center;
    }

    .page_julia {
        background-image: url(../assets/artist_pictures/julia/picture_0.webp);
        background-position: center bottom;

    }

    .page_leticia {
        background-image: url(../assets/artist_presentation_pictures/leticia_2.webp);
        background-position: center left;
    }

    .page_marina {
        background-image: url(../assets/artist_presentation_pictures/marina.webp);
    }

    .page_tatiane {
        background-image: url(../assets/artist_pictures/tatiane/picture_2.webp);
        background-position: right bottom;

    }

    .page_renata {
        background-image: url(../assets/artist_presentation_pictures/renata.webp);
        background-position: right bottom;

    }


}

